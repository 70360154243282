.stage-element-container:after {
  height: 315px;
}

.stage-mockup {
  position: absolute;
  order: 3;
  bottom: 0;
  right: 0;
  z-index: 9;
  width: 40vw;
  min-width: 645px;

  svg {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 1023px) {
  .stage-mockup {
    width: 515px;
    min-width: 0;
  }
}

@media only screen and (max-width: 767px) {
  .stage-mockup {
    width: 90vw;
    position: relative;
    order: 3;
    display: flex;
    justify-content: center;
  }
}